"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

var _modernizr = _interopRequireDefault(require("modernizr"));

var _nuxtPushPlugins = _interopRequireDefault(require("nuxt-push-plugins"));

var _path = _interopRequireDefault(require("path"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

async function _default(moduleOptions) {
  const options = { ...this.options.modernizr,
    ...moduleOptions
  };
  const code = await new Promise(resolve => _modernizr.default.build(options, resolve));
  (0, _nuxtPushPlugins.default)(this, {
    fileName: _path.default.join('nuxt-modernizr', 'plugin.js'),
    mode: 'client',
    options: code,
    src: require.resolve("./plugin.js.template")
  });
}

module.exports = exports.default;